<template>
    <v-card flat>
        <v-card-title class="mb-2">
            <v-btn
                text
                outlined
                depressed
                color="secondary"
                @click="dialog = true">
                <v-icon class="ml-1">mdi-plus-circle</v-icon>
                اضافة مستخدم جديد
            </v-btn>
            <v-divider class="mx-4 my-1" vertical> </v-divider>
            <v-spacer></v-spacer>
            <search-in-data-table />
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="users"
            :search="search"
            :loading="loading"
            no-data-text="لا توجد بيانات"
            loading-text="جاري تحميل البيانات"
            hide-default-footer
            class="elevation-1"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event">
            <!-- <template v-slot:[`item.groupName`]="{ item }">
                {{ getGroupName(item) }}
            </template> -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="changePassword(item)"
                            v-on="on"
                            fab
                            x-small
                            depressed
                        >
                            <v-icon color="secondary">
                                mdi-key
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تغير كلمة السر</span>
                </v-tooltip>
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="editItem(item)"
                            v-on="on"
                            fab
                            class="mr-2"
                            x-small
                            depressed
                        >
                            <v-icon color="secondary">
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تعديل</span>
                </v-tooltip> -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="deleteUser(item.id)"
                            v-on="on"
                            fab
                            x-small
                            depressed
                            class="mr-2"
                        >
                            <v-icon color="error">
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>حذف</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
            class="py-6">
        </v-pagination>

        <v-dialog v-model="dialog" persistent max-width="50%">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>
                            {{ formTitle }}
                        </v-toolbar-title>
                        <v-spacer />
                        <v-btn @click="close()" color="error">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="editedItem.userName"
                                        :rules="[rules.required]"
                                        outlined
                                        label="إسم المستخدم"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                        :items="groups"
                                        outlined
                                        v-model="editedItem.groupGuid"
                                        label="إسم المجموعة"
                                        item-text="name"
                                        item-value="id"
                                        :rules="[rules.required]"
                                    >
                                    </v-select>
                                </v-col>
                                <v-col
                                    v-if="editedIndex == -1"
                                    cols="12"
                                    md="6">
                                    <v-text-field
                                        v-model="editedItem.password"
                                        label="كلمة المرور"
                                        outlined
                                        color="third"
                                        :rules="[passwordFormat()]"
                                        :append-icon="
                                            showPassword
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off'
                                        "
                                        :type="
                                            showPassword ? 'text' : 'password'
                                        "
                                        @click:append="
                                            showPassword = !showPassword
                                        "
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col
                                    v-if="editedIndex == -1"
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        type="password"
                                        v-model="editedItem.confirmPassword"
                                        outlined
                                        :rules="[
                                            rules.required,
                                            rules.confirmPassword
                                        ]"
                                        label="تأكيد كلمة المرور"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="editedItem.email"
                                        outlined
                                        label="البريد الإلكتروني"
                                    ></v-text-field>
                                    <!-- :rules="[emailFormat()]" -->
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        outlined
                                        v-model="editedItem.phoneNumber"
                                        label="رقم الهاتف"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions class="mt-12">
                        <v-btn
                            x-large
                            color="primary"
                            @click="save"
                            :disabled="!valid"
                        >
                            حفظ
                        </v-btn>
                        <v-btn color="error" outlined x-large @click="close">
                            الغاء
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-dialog v-model="dialogChangePassword" persistent max-width="400px">
            <v-form ref="form1" v-model="valid" lazy-validation>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>
                            تغيير كلمة المرور
                        </v-toolbar-title>
                        <v-spacer />
                        <v-btn @click="closeChangePassword()" color="error">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="editedItem.password"
                                        label="كلمة المرور"
                                        :rules="[passwordFormat()]"
                                        :append-icon="
                                            showPassword
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off'
                                        "
                                        :type="
                                            showPassword ? 'text' : 'password'
                                        "
                                        @click:append="
                                            showPassword = !showPassword
                                        "
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                        type="password"
                                        v-model="editedItem.confirmPassword"
                                        :rules="[
                                            rules.required,
                                            rules.confirmPassword
                                        ]"
                                        label="تأكيد كلمة المرور"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            x-large
                            color="primary"
                            @click="saveChangePassword"
                            :disabled="!valid"
                        >
                            حفظ
                        </v-btn>
                        <v-btn
                            color="red darken-1"
                            text
                            @click="closeChangePassword"
                        >
                            إلغاء
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-card>
</template>
<script>
import validations from "@/utils/validations";
import SearchInDataTable from "../components/SearchInDataTable.vue";
export default {
    components: { SearchInDataTable },
    data() {
        return {
            loading: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,
            dialog: false,
            ...validations,
            showPassword: false,

            show: false,
            type: "success",
            message: "Completed Successfully",
            valid: true,
            dialogDelete: false,
            dialogChangePassword: false,
            editedIndex: -1,
            search: "",
            headers: [
                { text: "إسم المستخدم", value: "userName" },
                { text: "البريد الإلكتروني", value: "email" },
                { text: "رقم الهاتف", value: "phoneNumber" },
                { text: "إسم المجموعة", value: "groupName" },
                {
                    text: "الاجراءات",
                    value: "actions",
                    align: "left",
                    sortable: false
                }
            ],
            doctors: [],
            users: [],
            groups: [],
            branches: [],
            editedItem: {
                userName: "",
                email: null,
                password: "",
                confirmPassword: "",
                phoneNumber: null,
                groupGuid: ""
            },
            defaultItem: {
                userName: "",
                email: null,
                password: "",
                confirmPassword: "",
                phoneNumber: null,
                groupGuid: ""
            },

            rules: {
                required: value => !!value || "هذا الحقل مطلوب",
                confirmPassword: () =>
                    this.editedItem.confirmPassword ===
                        this.editedItem.password ||
                    "كلمة المرور لا تتطابق مع التأكيد"
            }
        };
    },
    created() {
        this.getUsers();
        this.getGroups();
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1
                ? "مستخدم  جديد"
                : " تعديل بيانات المستخدم";
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },

    methods: {
        getUsers() {
            this.$http
                .get("account/getUsers")
                .then(response => {
                    this.users = response.data.data;
                })
                .catch(err => {
                    console.log("err" + err);
                });
        },

        getGroups() {
            this.$http
                .get("account/getGroups")
                .then(response => {
                    this.groups = response.data.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        getGroupName(i) {
            var group = "";
            for (var l = 0; l < this.groups.length; l++) {
                var users = this.groups[l].applicationUsers;
                for (var y = 0; y < users.length; y++) {
                    if (users[y].applicationUserId == i.id) {
                        group = users[y].applicationGroupId;
                    }
                }
            }
            var result = this.groups.filter(obj => {
                return obj.id == group;
            });
            if (result[0]) {
                return result[0].name;
            }
        },

        changePassword(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogChangePassword = true;
        },

        deleteUser(id) {
            this.$GenericService.swalAlertConfirm({}).then(result => {
                if (result.isConfirmed) {
                    this.$GenericService
                        .delete(`account/deleteUser/${id}`)
                        .then(res => {
                            this.getUsers();
                            this.$GenericService.swalAlertSuccess();
                        })
                        .catch(err => {
                            this.$store.dispatch("setSnackbar", {
                                text: ` ${err.response.data.message}`,
                                color: "error"
                            });
                        });
                }
            });
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },

        closeChangePassword() {
            this.dialogChangePassword = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form1.reset();
            this.$refs.form1.resetValidation();
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save() {
            var val = this.$refs.form.validate();
            if (val) {
                if (this.editedIndex > -1) {
                    var updatedItem = this.users[this.editedIndex];
                    updatedItem.userName = this.editedItem.userName;
                    updatedItem.email = this.editedItem.email;
                    updatedItem.phoneNumber = this.editedItem.phoneNumber;
                    updatedItem.groupGuid = this.editedItem.groupGuid;
                    updatedItem.branchGuid = this.editedItem.branchGuid;
                    updatedItem.doctorGuid = this.editedItem.doctorGuid;

                    this.$http
                        .post("account/updateUser", updatedItem)
                        .then(res => {
                            this.getUsers();
                            this.$store.dispatch("setSnackbar", {
                                text: `تم التحديث بنجاح`
                            });
                        })
                        .catch(err => {
                            this.$store.dispatch("setSnackbar", {
                                text: ` ${err.data.message}`,
                                color: "error"
                            });
                            console.log(err);
                        });
                    Object.assign(
                        this.users[this.editedIndex],
                        this.editedItem
                    );
                } else {
                    this.$http
                        .post("account/addUser", this.editedItem)
                        .then(res => {
                            this.getUsers();
                            this.$store.dispatch("setSnackbar", {
                                text: `تم الإضافة بنجاح`
                            });
                        })
                        .catch(err => {
                            this.$store.dispatch("setSnackbar", {
                                text: ` ${err.data.message}`,
                                color: "error"
                            });
                            console.log(err);
                        });
                }
                this.close();
            }
        },

        saveChangePassword() {
            var val = this.$refs.form1.validate();
            if (val) {
                var updatedItem = this.users[this.editedIndex];
                var changePasswordModel = {
                    id: updatedItem.id,
                    password: this.editedItem.password,
                    confirmPassword: this.editedItem.confirmPassword
                };
                this.$http
                    .post(
                        "account/changeUserPasswordByAdmin",
                        changePasswordModel
                    )
                    .then(response => {
                        this.getUsers();
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
            this.closeChangePassword();
        }
    }
};
</script>

<style></style>
